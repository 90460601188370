import React, { ReactNode } from "react"
import { IonItem } from "@ionic/react"
import "./InputItem.css"

interface IInputItemProps {
  children: ReactNode
}

export default function InputItem({ children }: IInputItemProps) {
  return <IonItem className={"input-item"}>{children}</IonItem>
}
