import React, { createContext, useContext, useEffect, useReducer } from "react"
import config from "../config"
import { StartMethodResponse } from "../api/types/start_post.response"

type Action =
  | { type: "setLanguage"; language: string }
  | { type: "startSession"; payload: StartMethodResponse }
  | { type: "logIn"; username: string }
  | { type: "logOut" }

type Dispatch = (action: Action) => void

export interface UserState {
  token: string | null
  language: string
  usePassword: boolean
  useOtp: boolean
  username: string | null
}

interface Props {
  children: React.ReactNode
  mockState?: Partial<UserState>
}

const storedItem = window.localStorage.getItem(config.storage.keys.user) || "{}"
const persistedState: Partial<UserState> = JSON.parse(storedItem)
const defaultState: UserState = {
  token: null,
  language: config.locale.defaultLanguage,
  usePassword: true,
  useOtp: false,
  username: null,
}

const UserStateContext = createContext<
  { state: UserState; dispatch: Dispatch } | undefined
>(undefined)

const reducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case "logIn":
      return { ...state, username: action.username }
    case "logOut":
      return { ...state, username: null }
    case "setLanguage":
      return { ...state, language: action.language }
    case "startSession":
      return {
        ...state,
        token: action.payload.sessionToken,
        usePassword: action.payload.usePassword,
        useOtp: action.payload.useOtp,
      }
    default:
      return state
  }
}

function UserStateProvider({ children, mockState }: Props) {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...persistedState,
    ...(mockState && { ...mockState }),
  })

  useEffect(() => {
    localStorage.setItem(config.storage.keys.user, JSON.stringify(state))
  }, [state])

  const value = { state, dispatch }

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  )
}

function useUserState() {
  const context = useContext(UserStateContext)
  if (context === undefined)
    throw new Error("useUserState must be used within a UserProvider")
  return context
}

export { UserStateProvider, useUserState }
