import React, { FormEvent } from "react"
import { IonList, IonLoading } from "@ionic/react"
import { MessageId } from "../Message/Message"
import clsx from "clsx"
import useTranslate from "../../hooks/useTranslate"
import Button from "../Button/Button"

interface IFormProps {
  onSubmit: (e: FormEvent) => void
  submitMessage: MessageId
  isLoading: boolean
  children: React.ReactNode
  className?: string
}

export default function Form({
  onSubmit,
  children,
  className,
  submitMessage,
  isLoading,
}: IFormProps) {
  const translate = useTranslate()
  return (
    <form onSubmit={onSubmit} className={className} role={"form"}>
      <IonList>{children}</IonList>
      <Button
        messageId={submitMessage}
        size={"large"}
        fullWidth
        isSubmit
        className={clsx("ion-margin-top", isLoading && "pointer-events-none")}
      />
      <IonLoading isOpen={isLoading} message={translate("pleaseWait")} />
    </form>
  )
}
