import React from "react"
import { IonLabel, IonList, IonCheckbox } from "@ionic/react"
import { CheckboxChangeEventDetail } from "@ionic/core"
import InputItem from "../InputItem/InputItem"

interface ICheckboxListProps<T> {
  onChange: (value: T) => void
  items: [T, string][]
  slot?: string
  values?: T[]
}

export default function CheckboxList<T extends string>({
  items,
  values,
  onChange,
}: ICheckboxListProps<T>) {

  const handleChange = ({ detail }: CustomEvent<CheckboxChangeEventDetail>) => {
    onChange(detail.value)
  }

  return (
    <IonList>
      {items.map(([value, label]) => (
        <InputItem key={value}>
          <IonLabel>{label}</IonLabel>
          <IonCheckbox
            slot="end"
            value={value}
            onIonChange={handleChange}
            checked={values?.includes(value) || false}
          />
        </InputItem>
      ))}
    </IonList>
  )
}
