import React, { useEffect } from "react"
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  IonMenuButton,
  RefresherEventDetail,
  useIonAlert,
  IonSegment,
  IonSegmentButton,
  useIonRouter,
} from "@ionic/react"
import Message, { MessageId } from "../Message/Message"
import config from "../../config"
import useTranslate from "../../hooks/useTranslate"
import { SegmentChangeEventDetail } from "@ionic/core"
import { IonSegmentCustomEvent } from "@ionic/core/dist/types/components"

interface IPageLayoutProps {
  children: React.ReactNode
  backLink?: keyof typeof config.router.routes
  title?: MessageId
  icon?: string
  isLoading?: boolean
  onRefresh?: () => Promise<unknown>
  error?: Error | null
}

export default function PageLayout({
  children,
  title,
  isLoading,
  backLink,
  onRefresh,
  error,
}: IPageLayoutProps) {
  const translate = useTranslate()
  const { push, routeInfo } = useIonRouter()
  const [present] = useIonAlert()

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    if (onRefresh) {
      await onRefresh()
    }
    event.detail.complete()
  }

  const handleRedirect = (
    e: IonSegmentCustomEvent<SegmentChangeEventDetail>
  ) => {
    push(`${e.detail.value}`)
  }

  useEffect(() => {
    if (error) {
      present({
        message: error.message,
        header: translate("error"),
        buttons: onRefresh
          ? [
              { text: "Cancel", role: "cancel" },
              { text: "Try again", handler: onRefresh },
            ]
          : [{ text: "OK", role: "cancel" }],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <IonPage>
      {title && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton autoHide={false} />
              {backLink && (
                <IonBackButton defaultHref={config.router.routes[backLink]()} />
              )}
            </IonButtons>
            <IonTitle>
              <Message id={title} />
            </IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSegment onIonChange={handleRedirect} value={routeInfo.pathname}>
              <IonSegmentButton value={config.router.routes.favourites()}>
                <Message id={"favourites"} />
              </IonSegmentButton>
              <IonSegmentButton value={config.router.routes.dashboards()}>
                <Message id={"dashboards"} />
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent>
        {isLoading && <IonProgressBar type={"indeterminate"} />}
        <IonRefresher
          slot={"fixed"}
          onIonRefresh={handleRefresh}
          disabled={!onRefresh || isLoading}
        >
          <IonRefresherContent />
        </IonRefresher>
        {children}
      </IonContent>
    </IonPage>
  )
}
