import { FC, useMemo } from "react"
import { analyticsOutline } from "ionicons/icons"
import Container from "../../components/Container/Container"
import PageLayout from "../../components/PageLayout/PageLayout"
import { useRouteMatch } from "react-router"
import { useDashboardDetail } from "../../api/hooks"
import VerticalSpace from "../../components/VerticalSpace/VerticalSpace"
import RemoteText from "../../components/RemoteText/RemoteText"
import FilterForm, {
  FilterFormValues,
} from "../../components/FilterForm/FilterForm"
import { useFilterState } from "../../context/filterContext"
import GraphList from "../../components/GraphList/GraphList"
import Help from "../../components/Help/Help"
import { Dashboard as DashboardRes } from "../../api/types/customApiTypes"

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()
  const { data, isLoading, refetch, error } = useDashboardDetail(id)
  const dashboard = useMemo<DashboardRes | null>(() => {
    return data?.dashboards?.find((i) => i.id === id) || null
  }, [id, data?.dashboards])

  const [filters, setFilters] = useFilterState(id, "dashboard")

  const handleDashboardFilterFormSubmit = (values: FilterFormValues) => {
    setFilters(values)
  }

  return (
    <PageLayout
      backLink={"dashboards"}
      title={"dashboard"}
      icon={analyticsOutline}
      isLoading={isLoading}
      onRefresh={refetch}
      error={error}
    >
      <Container>
        <VerticalSpace>
          <Help id={id} help={dashboard?.annotation} fullWidth={false}>
            <RemoteText text={dashboard?.label} fraction={1} as={"h1"} />
          </Help>
          <RemoteText text={dashboard?.description} fraction={2 / 5} as={"p"} />
          <FilterForm
            onSubmit={handleDashboardFilterFormSubmit}
            filters={dashboard?.filters}
            values={filters}
            name={`dashboard-${id}`}
          />
        </VerticalSpace>
        <GraphList
          graphs={dashboard?.graphs || []}
          dashboardFilters={dashboard?.filters}
        />
      </Container>
    </PageLayout>
  )
}

export default Dashboard
