import React from "react"
import { IonChip, IonIcon, IonLabel } from "@ionic/react"
import { close } from "ionicons/icons"

interface IFilterChipProps {
  name: string
  label?: string
  onClick: (name: string) => void
}

export default function FilterChip({ name, label, onClick }: IFilterChipProps) {
  const handleClick = () => {
    onClick(name)
  }

  if (!label) return null

  return (
    <IonChip onClick={handleClick}>
      <IonLabel>{label}</IonLabel>
      <IonIcon icon={close} />
    </IonChip>
  )
}
