import React, { useMemo, useRef, useState } from "react"
import {
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonModal,
  IonToolbar,
} from "@ionic/react"
import "./DateInput.css"
import Button from "../Button/Button"
import { DatetimeChangeEventDetail } from "@ionic/core"
import useTranslate from "../../hooks/useTranslate"

interface IDateInputProps {
  onChange: (value: number) => void
  value?: number
  name: string
  min?: string
  max?: string
}

export default function DateInput({
  onChange,
  value,
  name,
  min,
  max,
}: IDateInputProps) {
  const trigger = `open-${name}-picker`
  const translate = useTranslate()
  const modal = useRef<HTMLIonModalElement>(null)
  const [date, setDate] = useState<Date | undefined>(() =>
    value ? new Date(value) : undefined
  )
  const formattedValue = useMemo<string>(
    () =>
      value ? new Date(value).toLocaleDateString() : translate("chooseDate"),
    [value, translate]
  )

  const handleChange = ({
    detail: { value: val },
  }: CustomEvent<DatetimeChangeEventDetail>) => {
    if (Array.isArray(val))
      throw new Error(`Event value is Array: [${val.join(", ")}]`)
    if (val) {
      const dateValue = new Date(val)
      if (dateValue) {
        setDate(dateValue)
      }
    }
  }

  const handleSubmit = () => {
    date && onChange(date.valueOf())
    modal.current?.dismiss()
  }

  const handleClose = () => {
    modal.current?.dismiss()
  }

  return (
    <>
      <span id={trigger}>{formattedValue}</span>
      <IonModal trigger={trigger} className={"date-input"} ref={modal}>
        <IonContent>
          <IonDatetime
            id={`${name}-date`}
            // presentation="date"
            onIonChange={handleChange}
            min={min}
            max={max}
            value={date?.toISOString()}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot={"end"}>
              <Button messageId={"close"} onClick={handleClose} />
              <Button
                messageId={"save"}
                color={"primary"}
                onClick={handleSubmit}
              />
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}
