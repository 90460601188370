import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { FilterFormValues } from "../components/FilterForm/FilterForm"
import config from "../config"

export type FilterState = Record<string, FilterFormValues>

type FilterNamespace = "dashboard" | "graph"

const FilterStateContext = createContext<
  [FilterState, Dispatch<SetStateAction<FilterState>>] | undefined
>(undefined)

interface Props {
  children: React.ReactNode
}

const storedItem =
  window.localStorage.getItem(config.storage.keys.filter) || "{}"

const persistedState: FilterState = JSON.parse(storedItem)

function FilterStateProvider({ children }: Props) {
  const [state, setState] = useState<FilterState>(persistedState)

  useEffect(() => {
    window.localStorage.setItem(
      config.storage.keys.filter,
      JSON.stringify(state)
    )
  }, [state])

  return (
    <FilterStateContext.Provider value={[state, setState]}>
      {children}
    </FilterStateContext.Provider>
  )
}

function useFilterState(
  id: string,
  namespace: FilterNamespace
): [FilterFormValues, (values: FilterFormValues) => void] {
  const context = useContext(FilterStateContext)
  if (context === undefined)
    throw new Error("useFilterState must be used within a FilterProvider")
  const [state, setState] = context
  const filterKey = createFilterKey(id, namespace)
  return [
    state[filterKey] || {},
    (values: FilterFormValues) => setState({ ...state, [filterKey]: values }),
  ]
}

function createFilterKey(id: string, namespace: FilterNamespace): string {
  return `${namespace}-${id}`
}

export { FilterStateProvider, useFilterState, createFilterKey }
