import React, { useRef, useState } from "react"
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonFooter,
  IonSpinner,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
} from "@ionic/react"
import { filter, close } from "ionicons/icons"
import Message from "../Message/Message"
import Button from "../Button/Button"
import { Filter } from "../../api/types/dashboard_detail_post.response"
import FilterInput, { FilterValue } from "../FilterInput/FilterInput"

export type FilterFormValues = Record<string, FilterValue>

interface IFilterFormProps {
  onSubmit: (values: FilterFormValues) => void
  name: string
  filters?: Filter[]
  values: FilterFormValues
  className?: string
  title?: string
}

export default function FilterForm({
  onSubmit,
  filters,
  values,
  name,
  className,
  title,
}: IFilterFormProps) {
  const modal = useRef<HTMLIonModalElement>(null)
  const [localValues, setLocalValues] = useState<FilterFormValues>(values)
  const trigger = `filter-form-${name}`

  const handleSave = () => {
    onSubmit({ ...values, ...localValues })
    modal.current?.dismiss()
  }

  const handleClear = () => {
    onSubmit({})
    setLocalValues({})
    modal.current?.dismiss()
  }

  const handleChange = (filter: Filter, value: FilterValue) => {
    setLocalValues({
      ...localValues,
      [filter.id]: value,
    })
  }

  return (
    <>
      <Button
        id={trigger}
        messageId={"filter"}
        color={"light"}
        size={"small"}
        icon={filter}
        className={className}
      />
      <IonModal trigger={trigger} ref={modal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title || <Message id={"generalFilters"} />}</IonTitle>
            <IonButtons slot="end">
              <Button color={"dark"} onClick={handleSave} icon={close} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {filters ? (
            <IonGrid>
              <IonRow>
                {filters.map((filter) => (
                  <IonCol
                    key={filter.id}
                    sizeLg={"3"}
                    sizeMd={"4"}
                    sizeSm={"6"}
                    size={"12"}
                  >
                    <h3>{filter.label}</h3>
                    <FilterInput
                      onChange={handleChange}
                      filter={filter}
                      value={localValues[filter.id]}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          ) : (
            <IonSpinner />
          )}
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot={"start"}>
              <Button
                messageId={"clearFilter"}
                fill={"outline"}
                color={"medium"}
                onClick={handleClear}
              />
            </IonButtons>
            <IonButtons slot={"end"}>
              <Button
                messageId={"save"}
                fill={"solid"}
                color={"primary"}
                onClick={handleSave}
              />
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}
