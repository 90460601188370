import React from "react"
import { FilterByListOfAvailableValues } from "../../api/types/dashboard_detail_post.response"
import RadioList from "../RadioList/RadioList"
import CheckboxList from "../CheckboxList/CheckboxList"

export type FilterSelectValue = string | string[]

interface IFilterSelectProps {
  onChange: (
    filter: FilterByListOfAvailableValues,
    value: FilterSelectValue
  ) => void
  filter: FilterByListOfAvailableValues
  value?: FilterSelectValue
}

export default function FilterSelect({
  onChange,
  filter,
  value,
}: IFilterSelectProps) {
  const handleSingleChange = (newVal: string) => {
    onChange(filter, newVal)
  }

  const handleMultipleChange = (newVal: string) => {
    const oldVal: string[] = Array.isArray(value) ? value : []
    onChange(
      filter,
      oldVal.includes(newVal)
        ? oldVal.filter((i) => i !== newVal)
        : [...oldVal, newVal]
    )
  }

  return filter.type === "LISTONE" &&
    (typeof value === "string" || value === undefined) ? (
    <RadioList
      onChange={handleSingleChange}
      items={Object.entries(filter.availableValues)}
      value={value}
    />
  ) : filter.type === "LISTMORE" &&
    (Array.isArray(value) || value === undefined) ? (
    <CheckboxList
      onChange={handleMultipleChange}
      items={Object.entries(filter.availableValues)}
      values={value}
    />
  ) : null
}
