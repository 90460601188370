import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import config from "../config"

const FavouritesStateContext = createContext<
  [string[], Dispatch<SetStateAction<string[]>>] | undefined
>(undefined)

interface Props {
  children: React.ReactNode
}

const storedItem =
  window.localStorage.getItem(config.storage.keys.favourites) || "[]"

const persistedState: string[] = JSON.parse(storedItem)

function FavouritesStateProvider({ children }: Props) {
  const [state, setState] = useState<string[]>(persistedState)

  useEffect(() => {
    window.localStorage.setItem(
      config.storage.keys.favourites,
      JSON.stringify(state)
    )
  }, [state])

  return (
    <FavouritesStateContext.Provider value={[state, setState]}>
      {children}
    </FavouritesStateContext.Provider>
  )
}

function useFavouritesState() {
  const context = useContext(FavouritesStateContext)
  if (context === undefined)
    throw new Error(
      "useFavouritesState must be used within a FavouritesProvider"
    )
  return context
}

export { FavouritesStateProvider, useFavouritesState }
