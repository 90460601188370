import React, { useMemo } from "react"
import {
  Filter,
  FilterByNumber,
} from "../../api/types/dashboard_detail_post.response"
import { IonCol, IonGrid, IonRange, IonRow, IonText } from "@ionic/react"
import { RangeChangeEventDetail, RangeValue } from "@ionic/core"
import Message from "../Message/Message"
import useForceUpdate from "../../hooks/useForceUpdate"

export type FilterNumberValue = {
  from: number
  to: number
}

interface IFilterNumberProps {
  onChange: (filter: Filter, value: FilterNumberValue) => void
  filter: FilterByNumber
  value?: FilterNumberValue
}

export default function FilterNumber({
  onChange,
  filter,
  value,
}: IFilterNumberProps) {
  const { from, to } = value || {}
  const { minValue, maxValue } = filter
  const update = useForceUpdate()

  const rangeValue: RangeValue = useMemo(
    () => ({
      lower: from || minValue || 0,
      upper: to || maxValue || 10e6,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [from, to, minValue, maxValue, update]
  )

  const handleChange = ({
    detail: { value },
  }: CustomEvent<RangeChangeEventDetail>) => {
    if (typeof value !== "number") {
      onChange(filter, { from: value.lower, to: value.upper })
    }
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText color={"medium"}>
              <Message id={"minimum"} />
            </IonText>
          </IonCol>
          <IonCol className={"ion-text-right"}>
            <IonText color={"medium"}>
              <Message id={"maximum"} />
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText color={"primary"}>{rangeValue.lower}</IonText>
          </IonCol>
          <IonCol className={"ion-text-right"}>
            <IonText color={"primary"}>{rangeValue.upper}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonRange
        dualKnobs
        debounce={200}
        value={rangeValue}
        max={maxValue}
        min={minValue}
        onIonChange={handleChange}
      />
    </>
  )
}
