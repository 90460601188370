import { useEffect, useState } from "react"

export default function useForceUpdate(): boolean {
  // ionic quirk
  const [update, setUpdate] = useState(false)
  useEffect(() => {
    setUpdate(true)
  }, [])
  return update
}
