import { useLanguageState } from "../context/languageContext"
import { MessageId } from "../components/Message/Message"
import translations from "../translations.json"
import { useCallback } from "react"
import {ClientError, ServerError} from "../utils/apiUtils"

export default function useTranslate(): (id: MessageId) => string {
  const [language] = useLanguageState()
  return useCallback((id: MessageId) => translations[language][id], [language])
}

export function useTranslateError(): (error: Error | null) => string {
  const translate = useTranslate()
  return useCallback(
    (error: Error | null) => {
      const messageId =
        error instanceof ClientError
          ? "invalidCredentials"
          : error instanceof ServerError
          ? "serverError"
          : error instanceof TypeError
          ? "fetchError"
          : error
          ? "error"
          : null
      return messageId ? translate(messageId) : ""
    },
    [translate]
  )
}
