import React from "react"
import { Filter } from "../../api/types/dashboard_detail_post.response"
import FilterDate, { FilterDateValue } from "../FilterDate/FilterDate"
import FilterSelect, { FilterSelectValue } from "../FilterSelect/FilterSelect"
import FilterNumber, { FilterNumberValue } from "../FilterNumber/FilterNumber"
import FilterString, { FilterStringValue } from "../FilterString/FilterString"

export type FilterValue =
  | FilterDateValue
  | FilterSelectValue
  | FilterNumberValue
  | FilterStringValue

interface IFilterInputProps {
  onChange: (filter: Filter, value: FilterValue) => void
  filter: Filter
  value?: FilterValue
}

export default function FilterInput({
  onChange,
  filter,
  value,
}: IFilterInputProps) {
  switch (filter.type) {
    case "DATE":
      return (
        <FilterDate
          onChange={onChange}
          filter={filter}
          value={value as FilterDateValue}
        />
      )
    case "NUMBER":
      return (
        <FilterNumber
          onChange={onChange}
          filter={filter}
          value={value as FilterNumberValue}
        />
      )
    case "STRING":
      return (
        <FilterString
          onChange={onChange}
          filter={filter}
          value={value as FilterStringValue}
        />
      )
    case "LISTMORE":
    case "LISTONE":
      return (
        <FilterSelect
          onChange={onChange}
          filter={filter}
          value={value as FilterSelectValue}
        />
      )
    default:
      return null
  }
}
