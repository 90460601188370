import React, { useMemo } from "react"
import { Filter } from "../../api/types/graph_detail_post.response"
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react"
import RemoteText from "../RemoteText/RemoteText"
import FilterForm, { FilterFormValues } from "../FilterForm/FilterForm"
import { useFilterState } from "../../context/filterContext"
import FilterChip from "../FilterChip/FilterChip"
import Chart from "../Chart/Chart"
import { useGraphGet } from "../../api/hooks"
import "./GraphCard.css"
import Help from "../Help/Help"

interface IGraphCardProps {
  id: string
  label?: string
  description?: string
  annotation?: string
  aspectRatio?: number
  filters?: Filter[]
}

export default function GraphCard({
  id,
  label,
  description,
  annotation,
  aspectRatio,
  filters,
}: IGraphCardProps) {
  const [filterValues, setFilterValues] = useFilterState(id, "graph")
  const { data } = useGraphGet(id, filterValues)

  const filterLabelMap = useMemo<Map<string, string>>(
    () => new Map(filters?.map((filter) => [filter.id, filter.label]) || []),
    [filters]
  )

  const handleSubmit = (values: FilterFormValues) => {
    setFilterValues(values)
  }

  const handleFilterRemove = (key: string) => {
    const { [key]: omit, ...filtersToPreserve } = filterValues
    setFilterValues(filtersToPreserve)
  }

  return (
    <IonCard className={"graph-card"}>
      <IonCardHeader>
        <Help id={id} help={annotation}>
          <IonCardTitle slot={"start"}>
            <RemoteText text={label} fraction={2 / 3} as={"h3"} />
          </IonCardTitle>
        </Help>
        <IonCardSubtitle className={"ion-padding-bottom"}>
          <RemoteText text={description} as={"p"} />
        </IonCardSubtitle>
        {Object.keys(filterValues).map((key) => (
          <FilterChip
            key={key}
            name={key}
            label={filterLabelMap.get(key)}
            onClick={handleFilterRemove}
          />
        ))}
      </IonCardHeader>
      <IonCardContent className={"ion-text-right"}>
        <Chart
          type={data?.graphType}
          data={data?.graphData}
          options={data?.graphOptions}
          aspectRatio={aspectRatio}
        />
        <FilterForm
          onSubmit={handleSubmit}
          name={`graph-filter-${id}`}
          filters={filters}
          values={filterValues}
          className={"ion-margin-top"}
          title={label}
        />
      </IonCardContent>
    </IonCard>
  )
}
