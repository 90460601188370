import React, { Children, ReactNode } from "react"
import { IonCol, IonGrid, IonRow } from "@ionic/react"

interface IVerticalSpaceProps {
  children: ReactNode
}

export default function VerticalSpace({ children }: IVerticalSpaceProps) {
  return (
    <IonGrid>
      {Children.map(children, (child, idx) => (
        <IonRow key={idx}>
          <IonCol>{child}</IonCol>
        </IonRow>
      ))}
    </IonGrid>
  )
}
