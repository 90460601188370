import React from "react"
import PageLayout from "../../components/PageLayout/PageLayout"
import { warning } from "ionicons/icons"
import Container from "../../components/Container/Container"
import { useTranslateError } from "../../hooks/useTranslate"
import VerticalSpace from "../../components/VerticalSpace/VerticalSpace"

interface IErrorProps {
  error: Error
}

export default function Error({ error }: IErrorProps) {
  const translate = useTranslateError()
  return (
    <PageLayout title={"error"} icon={warning}>
      <Container width={"small"}>
        <VerticalSpace>
          <h1>{translate(error)}</h1>
          <p>{error.message}</p>
          <p>{error.stack}</p>
        </VerticalSpace>
      </Container>
    </PageLayout>
  )
}
