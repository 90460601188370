import React from "react"
import ReactDOM from "react-dom"
import { QueryClientProvider, QueryClient } from "react-query"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import { UserStateProvider } from "./context/userContext"
import { ReactQueryDevtools } from "react-query/devtools"
import { LanguageStateProvider } from "./context/languageContext"
import { FilterStateProvider } from "./context/filterContext"
import { FavouritesStateProvider } from "./context/favouritesContext"

const queryClient = new QueryClient()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.NODE_ENV === "development") {
  const { worker } = require("./api/mocks/browser")
  worker.start({
    onUnhandledRequest: "bypass",
    quiet: true
  })
} else {
  serviceWorkerRegistration.unregister()
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <FavouritesStateProvider>
        <UserStateProvider>
          <LanguageStateProvider>
            <FilterStateProvider>
              <App />
            </FilterStateProvider>
          </LanguageStateProvider>
        </UserStateProvider>
      </FavouritesStateProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
