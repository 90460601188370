import { FC } from "react"
import { analyticsOutline } from "ionicons/icons"
import Container from "../../components/Container/Container"
import PageLayout from "../../components/PageLayout/PageLayout"
import DashboardList from "../../components/DashboardList/DashboardList"
import { useDashboardList } from "../../api/hooks"

const Dashboards: FC = () => {
  const { data, isLoading, refetch, error } = useDashboardList()

  return (
    <PageLayout
      title={"dashboards"}
      icon={analyticsOutline}
      isLoading={isLoading}
      onRefresh={refetch}
      error={error}
    >
      <Container>
        <DashboardList list={data?.dashboards || []} isLoading={isLoading} />
      </Container>
    </PageLayout>
  )
}

export default Dashboards
