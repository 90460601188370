import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react"
import { localeEnglish } from "../config"

export type Language = typeof localeEnglish

const LanguageStateContext = createContext<
  [Language, Dispatch<SetStateAction<Language>>] | undefined
>(undefined)

interface Props {
  children: React.ReactNode
}

function LanguageStateProvider({ children }: Props) {
  const value = useState<Language>(localeEnglish)
  return (
    <LanguageStateContext.Provider value={value}>
      {children}
    </LanguageStateContext.Provider>
  )
}

function useLanguageState() {
  const context = useContext(LanguageStateContext)
  if (context === undefined)
    throw new Error("useLanguageState must be used within a LanguageProvider")
  return context
}

export { LanguageStateProvider, useLanguageState }
