import { FC } from "react"
import { bookmarksOutline } from "ionicons/icons"
import Container from "../../components/Container/Container"
import PageLayout from "../../components/PageLayout/PageLayout"
import DashboardList from "../../components/DashboardList/DashboardList"
import { useDashboardList } from "../../api/hooks"
import { useFavouritesState } from "../../context/favouritesContext"
import Message from "../../components/Message/Message"
import Button from "../../components/Button/Button"
import VerticalSpace from "../../components/VerticalSpace/VerticalSpace"
import config from "../../config"

const Favourites: FC = () => {
  const [favourites] = useFavouritesState()
  const { data, isLoading, refetch, error } = useDashboardList()

  return (
    <PageLayout
      title={"favourites"}
      icon={bookmarksOutline}
      isLoading={isLoading}
      onRefresh={refetch}
      error={error}
    >
      <Container>
        {favourites.length > 0 ? (
          <DashboardList
            list={
              data?.dashboards?.filter((dashboard) =>
                favourites.includes(dashboard.id)
              ) || []
            }
            isLoading={isLoading}
          />
        ) : (
          <Container center width={"small"}>
            <VerticalSpace>
              <Message id={"addFavourites"} />
              <Button
                routerLink={config.router.routes.dashboards()}
                color={"primary"}
                fill={"outline"}
                messageId={"dashboards"}
              />
            </VerticalSpace>
          </Container>
        )}
      </Container>
    </PageLayout>
  )
}

export default Favourites
