import React from "react"
import {
  InputChangeEventDetail,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react"

interface IInputProps {
  onChange: (value: string) => void
  value?: string
  name: string
  label: string
  error?: string | null
  type?: "text" | "password" | "email" | "tel"
  required?: boolean
  autofocus?: boolean
  labelPosition?: "fixed" | "floating"
}

export default function Input({
  onChange,
  error,
  value,
  type = "text",
  name,
  label,
  required,
  autofocus,
  labelPosition = "floating",
}: IInputProps) {
  const handleChange = (e: CustomEvent<InputChangeEventDetail>) => {
    onChange(e.detail.value || "")
  }

  return (
    <>
      <IonItem color={error ? "danger" : undefined}>
        <IonLabel position={labelPosition}>{label}</IonLabel>
        <IonInput
          title={label}
          required={required}
          autofocus={autofocus}
          name={name}
          type={type}
          value={value}
          onIonChange={handleChange}
          {...(error && {
            "aria-invalid": true,
            "aria-errormessage": `error-${name}`,
          })}
        />
      </IonItem>
      {error && (
        <IonText color={"danger"} id={`error-${name}`}>
          <small>{error}</small>
        </IonText>
      )}
    </>
  )
}
