import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonLoading,
  IonMenuToggle,
  IonToolbar,
  IonButtons,
  IonTitle,
} from "@ionic/react"
import { useLocation } from "react-router-dom"
import {
  arrowForward,
  bookmarksSharp,
  bookmarksOutline,
  analyticsOutline,
  analyticsSharp,
  closeSharp,
  personCircleOutline,
  informationCircleOutline,
  constructOutline,
} from "ionicons/icons"
import "./Navigation.css"
import config from "../../config"
import Message, { MessageId } from "../Message/Message"
import React from "react"
import Button from "../Button/Button"
import List from "../List/List"
import { useUserState } from "../../context/userContext"
import { useLogout } from "../../api/hooks"
import useTranslate from "../../hooks/useTranslate"

interface AppPage {
  url: string
  iosIcon: string
  mdIcon: string
  title: MessageId
}

const appPages: AppPage[] = [
  {
    title: "favourites",
    url: config.router.routes.favourites(),
    iosIcon: bookmarksOutline,
    mdIcon: bookmarksSharp,
  },
  {
    title: "dashboards",
    url: config.router.routes.dashboards(),
    iosIcon: analyticsOutline,
    mdIcon: analyticsSharp,
  },
]

const Navigation: React.FC = () => {
  const location = useLocation()
  const translate = useTranslate()
  const {
    state: { username },
  } = useUserState()
  const { isLoading, logout } = useLogout()

  const handleLogOut = async () => {
    await logout()
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonLoading isOpen={isLoading} message={translate("loggingOut")} />
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuToggle>
            <Button fill={"clear"} icon={closeSharp} color={"dark"} />
          </IonMenuToggle>
        </IonButtons>
        <IonTitle>Menu</IonTitle>
      </IonToolbar>
      <IonList id="inbox-list">
        {appPages.map((appPage, index) => {
          return (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                className={
                  location.pathname.startsWith(appPage.url) ? "selected" : ""
                }
                routerLink={appPage.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon
                  slot="start"
                  ios={appPage.iosIcon}
                  md={appPage.mdIcon}
                />
                <IonLabel>
                  <Message id={appPage.title} />
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          )
        })}
      </IonList>
      <List
        items={[
          {
            content: username,
            icon: personCircleOutline,
            renderButton: (props) => (
              <Button
                messageId={"logOut"}
                color={"danger"}
                onClick={handleLogOut}
                {...props}
              />
            ),
          },
          {
            content: translate("appInformation"),
            icon: constructOutline,
            renderButton: (props) => (
              <Button
                {...props}
                fill={"clear"}
                icon={arrowForward}
                color={"dark"}
              />
            ),
          },
          {
            content: translate("support"),
            icon: informationCircleOutline,
            renderButton: (props) => (
              <Button messageId={"contact"} {...props} />
            ),
          },
        ]}
      />
    </IonMenu>
  )
}

export default Navigation
