import React, { ReactNode } from "react"
import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react"
import { ButtonProps } from "../Button/Button"

interface Item {
  content?: string | null
  icon?: string
  renderButton?: (props: Partial<ButtonProps>) => ReactNode
}

interface IListProps {
  items: Item[]
}

export default function List({ items }: IListProps) {
  return (
    <IonList lines={"none"}>
      {items.map(({ content, icon, renderButton }) => (
        <IonItem key={content}>
          {icon && <IonIcon icon={icon} slot="start" />}
          <IonLabel>{content}</IonLabel>
          {renderButton ? renderButton({ slot: "end", fill: "outline" }) : null}
        </IonItem>
      ))}
    </IonList>
  )
}
