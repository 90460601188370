const apiBasePath = "https://septim-api.visu.cz/"
const storageKeyPrefix = "septim/"
export const localeEnglish = "en_US"

const config = {
  appName: "MySeptim",
  router: {
    routes: {
      favourites: () => "/favourites",
      dashboards: () => "/dashboards",
      dashboard: (id: string = ":id") => `/dashboards/${id}`,
    },
  },
  locale: {
    languages: [localeEnglish],
    defaultLanguage: localeEnglish,
  },
  storage: {
    keys: {
      user: storageKeyPrefix + "user",
      filter: storageKeyPrefix + "filer",
      language: storageKeyPrefix + "language",
      favourites: storageKeyPrefix + "favourites",
    },
  },
  api: {
    basePath: apiBasePath,
    routes: {
      dashboardDetail: apiBasePath + "dashboard/detail",
      dashboardList: apiBasePath + "dashboard/list",
      graphGet: apiBasePath + "graph/get",
      graphDetail: apiBasePath + "graph/detail",
      login: apiBasePath + "login",
      logout: apiBasePath + "logout",
      setLanguage: apiBasePath + "setLanguage",
      start: apiBasePath + "start",
    },
  },
}

export default config
