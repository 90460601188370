import React from "react"
import { IonCol, IonGrid, IonRow } from "@ionic/react"

type ContainerWidth = "small" | "default" | "large"
type Breakpoints = "sm" | "md" | "lg" | "xl"

interface IContainerProps {
  children: React.ReactNode
  width?: ContainerWidth
  center?: boolean
}

const map: Record<ContainerWidth, Record<Breakpoints, number>> = {
  small: {
    sm: 10,
    md: 8,
    lg: 6,
    xl: 4,
  },
  default: {
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  large: {
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
  },
}

export default function Container({
  children,
  center = false,
  width = "default",
}: IContainerProps) {
  return (
    <IonGrid>
      <IonRow className={"ion-justify-content-center h-full"}>
        <IonCol
          size={"12"}
          sizeSm={`${map[width].sm}`}
          sizeMd={`${map[width].md}`}
          sizeLg={`${map[width].lg}`}
          sizeXl={`${map[width].xl}`}
          className={center ? "ion-align-self-center ion-text-center" : undefined}
        >
          {children}
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
