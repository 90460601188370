import React from "react"
import {
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react"
import { RadioGroupChangeEventDetail } from "@ionic/core"
import InputItem from "../InputItem/InputItem"

interface IRadioListProps<T> {
  onChange: (value: T) => void
  items: [T, string][]
  slot?: string
  value?: T
}

export default function RadioList<T extends string>({
  items,
  value,
  onChange,
}: IRadioListProps<T>) {
  const handleChange = ({
    detail,
  }: CustomEvent<RadioGroupChangeEventDetail>) => {
    onChange(detail.value)
  }

  return (
    <IonList>
      <IonRadioGroup value={value} onIonChange={handleChange}>
        {items.map(([value, label]) => (
          <InputItem key={value}>
            <IonLabel>{label}</IonLabel>
            <IonRadio slot="end" value={value} />
          </InputItem>
        ))}
      </IonRadioGroup>
    </IonList>
  )
}
