import React from "react"
import { IonSelect, IonSelectOption } from "@ionic/react"
import { SelectChangeEventDetail } from "@ionic/core"

interface ISelectProps<T> {
  onChange: (value: T) => void
  label: string
  items: [T, string][]
  slot?: string
  value?: T
}

export default function Select<T extends string>({
  onChange,
  items,
  label,
  slot,
  value,
}: ISelectProps<T>) {

  const handleChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    onChange(event.detail.value as T)
  }

  return (
    <IonSelect
      placeholder={label}
      slot={slot}
      onIonChange={handleChange}
      value={value}
      data-testid={"select"}
    >
      {items.map(([value, label]) => (
        <IonSelectOption value={value} key={value}>
          {label}
        </IonSelectOption>
      ))}
    </IonSelect>
  )
}
