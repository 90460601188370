import React from "react"
import translations from "../../translations.json"
import { useLanguageState } from "../../context/languageContext"
import {PredefinedColors} from "@ionic/core";

export type MessageId = keyof typeof translations["en_US"]

interface IMessageProps {
  id: MessageId
  as?: keyof JSX.IntrinsicElements
  className?: string
  color?: PredefinedColors
}

export default function Message({
  id,
  as: Element = "span",
  className,
}: IMessageProps) {
  const [language] = useLanguageState()
  return (
    <Element className={className}>{translations?.[language]?.[id]}</Element>
  )
}
