import React, { SyntheticEvent } from "react"
import {
  IonButton,
  IonIcon,
  IonItem,
  IonPopover,
  IonContent,
} from "@ionic/react"
import { informationCircleOutline } from "ionicons/icons"
import "./Help.css"
import clsx from "clsx"

interface IHelpProps {
  children: React.ReactNode
  id: string
  help?: string
  fullWidth?: boolean
}

export default function Help({
  children,
  id,
  help,
  fullWidth = true,
}: IHelpProps) {
  const handlePrevent = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <IonItem lines={"none"} className={clsx("ion-no-padding", "help", fullWidth && "help--full")}>
      {children}
      {help && (
        <>
          <IonButton
            fill="clear"
            color={"dark"}
            slot="end"
            id={id}
            onClick={handlePrevent}
          >
            <IonIcon icon={informationCircleOutline} slot="icon-only" />
          </IonButton>
          <IonPopover trigger={id} triggerAction="click">
            <IonContent class="ion-padding">{help}</IonContent>
          </IonPopover>
        </>
      )}
    </IonItem>
  )
}
