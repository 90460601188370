import React from "react"
import { IonSkeletonText, IonText } from "@ionic/react"

interface IRemoteTextProps {
  text?: string
  fraction?: number
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "span" | "p"
}

export default function RemoteText({
  text,
  fraction,
  as: Element = "span",
}: IRemoteTextProps) {
  return (
    <Element style={text === undefined ? { position: "relative", minWidth: "240px" } : {}}>
      {text === undefined ? (
        <IonSkeletonText
          style={
            fraction
              ? {
                  width: `${fraction * 100}%`,
                  height: "100%",
                  position: "absolute",
                }
              : {}
          }
          animated
        />
      ) : (
        <IonText>{text}</IonText>
      )}
      {"\u00A0"}
    </Element>
  )
}
