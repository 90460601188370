import React from "react"
import Message, { MessageId } from "../Message/Message"
import { PredefinedColors } from "@ionic/core"
import { IonButton, IonIcon } from "@ionic/react"
import useTranslate from "../../hooks/useTranslate"

export interface ButtonProps {
  messageId?: MessageId
  isSubmit?: boolean
  fullWidth?: boolean
  className?: string
  color?: PredefinedColors
  onClick?: () => void
  fill?: "clear" | "outline" | "solid" | "default"
  size?: "small" | "default" | "large"
  slot?: string
  icon?: string
  id?: string
  routerLink?: string
}

export default function Button({
  messageId,
  fullWidth = false,
  fill,
  color,
  onClick,
  size,
  className,
  isSubmit = false,
  icon,
  id,
  routerLink,
}: ButtonProps) {
  const translate = useTranslate()

  return (
    <IonButton
      routerLink={routerLink}
      id={id}
      color={color}
      fill={fill}
      onClick={onClick}
      size={size}
      className={className}
      expand={fullWidth ? "block" : undefined}
      type={isSubmit ? "submit" : undefined}
      title={messageId ? translate(messageId) : undefined}
    >
      {messageId && <Message id={messageId} />}
      {icon && <IonIcon slot={messageId ? "start" : "icon-only"} icon={icon} />}
    </IonButton>
  )
}
