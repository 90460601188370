import React, { SyntheticEvent } from "react"
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonThumbnail,
  IonSkeletonText,
  IonIcon,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react"
import { imageOutline, bookmarkOutline, bookmark } from "ionicons/icons"
import config from "../../config"
import RemoteText from "../RemoteText/RemoteText"
import Help from "../Help/Help"
import "./DashboardCard.css"

interface IDashboardCardProps {
  id?: string
  label?: string
  description?: string
  icon?: string
  help?: string
  favourite?: boolean
  onAddToFavourites?: (id: string) => void
}

export default function DashboardCard({
  id,
  label,
  description,
  icon,
  help,
  favourite,
  onAddToFavourites,
}: IDashboardCardProps) {
  const handleAddToFavourites = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    id && onAddToFavourites && onAddToFavourites(id)
  }

  return (
    <IonCard
      routerLink={id ? config.router.routes.dashboard(id) : undefined}
      className={"dashboard-card"}
    >
      <IonThumbnail
        style={{ "--size": "100%", aspectRatio: "1 / 1" }}
        className={"center"}
      >
        {icon ? (
          <IonImg src={icon} />
        ) : (
          <>
            <IonSkeletonText />
            <IonIcon icon={imageOutline} size={"large"} />
          </>
        )}
      </IonThumbnail>

      <IonCardHeader>
        <Help help={help} id={id || ""}>
          <IonCardTitle slot={"start"}>
            <RemoteText text={label} fraction={2 / 3} as={"h3"} />
          </IonCardTitle>
        </Help>
      </IonCardHeader>

      <IonItem lines={"none"}>
        <IonLabel>
          <RemoteText text={description} as={"p"} />
        </IonLabel>
        <IonButton
          fill="clear"
          color={"dark"}
          slot="end"
          onClick={handleAddToFavourites}
        >
          <IonIcon
            icon={favourite ? bookmark : bookmarkOutline}
            slot="icon-only"
          />
        </IonButton>
      </IonItem>
    </IonCard>
  )
}
