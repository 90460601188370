import React, { FormEvent, useState } from "react"
import { IonText } from "@ionic/react"
import { useUserState } from "../../context/userContext"
import Message from "../../components/Message/Message"
import Input from "../../components/Input/Input"
import Container from "../../components/Container/Container"
import PageLayout from "../../components/PageLayout/PageLayout"
import Form from "../../components/Form/Form"
import useTranslate, {useTranslateError} from "../../hooks/useTranslate"
import { useLogin } from "../../api/hooks"

const Login: React.FC = () => {
  const {
    state: { usePassword, useOtp },
  } = useUserState()
  const translate = useTranslate()
  const translateError = useTranslateError()
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const { login, isLoading, error } = useLogin()

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault()
    await login({
      userid: username,
      otp: useOtp ? password : undefined,
      password: usePassword ? password : undefined,
    })
  }

  return (
    <PageLayout>
      <Container width={"small"}>
        <IonText color={"dark"}>
          <Message id={"welcomeBack"} as={"h1"} />
        </IonText>
        <IonText color={"medium"}>
          <Message
            id={"enterYourCredentials"}
            as={"p"}
            className={"ion-no-margin"}
          />
        </IonText>
        <Form
          onSubmit={handleLogin}
          submitMessage={"logIn"}
          className={"ion-margin-top"}
          isLoading={isLoading}
        >
          <Input
            onChange={setUsername}
            value={username}
            name={"username"}
            label={translate("username")}
            error={translateError(error)}
            required
          />
          {usePassword && (
            <Input
              onChange={setPassword}
              value={password}
              name={"password"}
              label={translate("password")}
              type={"password"}
            />
          )}
        </Form>
      </Container>
    </PageLayout>
  )
}

export default Login
