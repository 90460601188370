import config from "../config"
import { LoginMethodResponse } from "./types/login_post.response"
import { LoginMethodBody } from "./types/login_post.content"
import { StartMethodResponse } from "./types/start_post.response"
import { fetcher } from "../utils/apiUtils"

export async function requestStart(): Promise<StartMethodResponse> {
  return fetcher<StartMethodResponse>(config.api.routes.start, "POST", null)
}

export function requestLogin(
  body: LoginMethodBody,
  token: string | null
): Promise<LoginMethodResponse> {
  return fetcher<LoginMethodResponse>(
    config.api.routes.login,
    "POST",
    token,
    undefined,
    body
  )
}

export function requestLogout(
  token: string | null
): Promise<LoginMethodResponse> {
  return fetcher<LoginMethodResponse>(config.api.routes.logout, "POST", token)
}
