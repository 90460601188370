import React, { useEffect, useMemo, useRef } from "react"
import { ChartData, ChartType } from "chart.js"
import ChartLib from "chart.js/auto"
import {
  GraphData,
  GraphOptions,
} from "../../api/types/graph_get_post.response"
import { IonSpinner } from "@ionic/react"

interface IChartProps {
  data?: GraphData
  options?: GraphOptions
  type?: ChartType
  aspectRatio?: number
}

export default function Chart({
  data,
  options,
  type,
  aspectRatio,
}: IChartProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const chartRef = useRef<ChartLib | null>(null)
  const chartData = data as unknown as ChartData // trust the API or change in the future
  const chartOptions = useMemo(
    () => ({ ...options, aspectRatio }),
    [options, aspectRatio]
  )

  useEffect(() => {
    if (chartRef.current) {
      if (chartData) chartRef.current.data = chartData
      if (chartOptions) chartRef.current.options = chartOptions
      chartRef.current.update()
    } else {
      const context = canvasRef.current?.getContext("2d")
      if (context && type && chartData)
        chartRef.current = new ChartLib(context, {
          type,
          data: chartData, // trust the API
          options: chartOptions,
        })
    }
  }, [chartData, type, chartOptions])

  return (
    <div
      style={{ aspectRatio: aspectRatio?.toString() || "1" }}
      className={"center"}
    >
      <canvas ref={canvasRef} style={{ width: "100%" }} />
      {!type && <IonSpinner />}
    </div>
  )
}
