import { QueryKey, useQuery, UseQueryOptions } from "react-query"
import { useUserState } from "../context/userContext"
import { Body, fetcher, HttpMethod, Params } from "../utils/apiUtils"
import { Result } from "../api/types/dashboard_detail_post.response"
import { UseQueryResult } from "react-query/types/react/types"
import { useEffect } from "react"
import { useIonToast } from "@ionic/react"
import { warning } from "ionicons/icons"

interface ApiResult {
  result?: Result
}

export const useFetch = <T extends ApiResult>(
  url: string,
  method: HttpMethod,
  params?: Params,
  body?: Body,
  config?: UseQueryOptions<T, Error, T>
): UseQueryResult<T, Error> => {
  const {
    state: { token },
  } = useUserState()
  const [showToast, closeToast] = useIonToast()

  const queryResult = useQuery<T, Error, T, QueryKey>(
    [url, method, params, body],
    () => fetcher(url, method, token, params, body),
    {
      ...config,
      staleTime: 5 * 60 * 1000, // consider as fresh for five minutes
    }
  )
  const result = queryResult?.data?.result
  useEffect(() => {
    if (result && result.status !== "OK") {
      showToast({
        message: result.message || result.status,
        duration: 3000,
        color:
          result.status === "WARNING"
            ? "warning"
            : result.status === "ERROR"
            ? "danger"
            : "success",
        icon: warning,
        buttons: [
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              closeToast()
            },
          },
        ],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])
  return queryResult
}
