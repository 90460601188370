import config from "../config"
import { useFetch } from "../hooks/useFetch"
import { DashboardListMethodResponse } from "./types/dashboard_list_get.response"
import { DashboardDetailGetMethodResponse } from "./types/dashboard_detail_post.response"
import { GraphGetMethodBody } from "./types/graph_get_post.content"
import { GraphGetMethodResponse } from "./types/graph_get_post.response"
import { FilterFormValues } from "../components/FilterForm/FilterForm"
import { transformFilterValueToArray } from "../utils/apiUtils"
import { useMutation } from "react-query"
import { LogoutMethodResponse } from "./types/logout_post.response"
import { requestLogin, requestLogout, requestStart } from "./requests"
import { useUserState } from "../context/userContext"
import { LoginMethodBody } from "./types/login_post.content"
import { useEffect, useState } from "react"
import { StartMethodResponse } from "./types/start_post.response"
import { useIonAlert, useIonLoading } from "@ionic/react"
import { DashboardDetailMethodBody } from "./types/dashboard_detail_post.content"
import { GraphDetailMethodBody } from "./types/graph_detail_post.content"
import { GraphDetailGetMethodResponse } from "./types/graph_detail_post.response"

export const useDashboardList = () => {
  return useFetch<DashboardListMethodResponse>(
    config.api.routes.dashboardList,
    "GET"
  )
}

export const useDashboardDetail = (id: string) => {
  const body: DashboardDetailMethodBody = {
    dashboards: [id],
  }
  return useFetch<DashboardDetailGetMethodResponse>(
    config.api.routes.dashboardDetail,
    "POST",
    undefined,
    body
  )
}

export const useGraphDetails = (ids: string[]) => {
  const body: GraphDetailMethodBody = {
    graphs: ids,
  }
  return useFetch<GraphDetailGetMethodResponse>(
    config.api.routes.graphDetail,
    "POST",
    undefined,
    body
  )
}

export const useGraphGet = (id: string, filters: FilterFormValues) => {
  const body: GraphGetMethodBody = {
    graphId: id,
    filters: Object.entries(filters).map(([filterId, values]) => ({
      filterId,
      values: transformFilterValueToArray(values),
    })),
  }

  return useFetch<GraphGetMethodResponse>(
    config.api.routes.graphGet,
    "POST",
    undefined,
    body
  )
}

export const useLogout = () => {
  const {
    state: { token },
    dispatch,
  } = useUserState()
  const {
    isLoading,
    error,
    mutateAsync: logout,
  } = useMutation<LogoutMethodResponse, Error>(() => requestLogout(token), {
    onSuccess: () => {
      dispatch({ type: "logOut" })
    },
  })
  return { isLoading, error, logout }
}

export const useLogin = () => {
  const {
    state: { token },
    dispatch,
  } = useUserState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  return {
    isLoading,
    error,
    login: async (body: LoginMethodBody) => {
      try {
        setIsLoading(true)
        if (!body.userid)
          throw new Error("missing userid")
        const { language } = await requestLogin(body, token)
        dispatch({ type: "logIn", username: body.userid })
        if (language) dispatch({ type: "setLanguage", language })
      } catch (e) {
        if (e instanceof Error) setError(e)
      } finally {
        setIsLoading(false)
      }
    },
  }
}

export const useStart = () => {
  const {
    state: { username, token },
    dispatch,
  } = useUserState()
  const [showLoading, hideLoading] = useIonLoading()
  const [showAlert, hideAlert] = useIonAlert()

  const {
    isLoading,
    error,
    mutateAsync: start,
  } = useMutation<StartMethodResponse, Error>(requestStart, {
    onSuccess: (response) => {
      dispatch({ type: "startSession", payload: response })
    },
    retry: 3,
  })

  useEffect(() => {
    if (!token) {
      start()
    }
  }, [start, token])

  useEffect(() => {
    isLoading ? showLoading("Starting the app") : hideLoading()
    return () => {
      hideLoading()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    error ? showAlert("Could not start the app") : hideAlert()
    return () => {
      hideAlert()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return {
    isLoggedIn: username && token,
    error,
  }
}
