import React from "react"
import { FilterByString } from "../../api/types/dashboard_detail_post.response"
import Input from "../Input/Input"

export type FilterStringValue = string

interface IFilterStringProps {
  onChange: (filter: FilterByString, value: FilterStringValue) => void
  filter: FilterByString
  value?: FilterStringValue
}

export default function FilterString({
  onChange,
  filter,
  value,
}: IFilterStringProps) {
  const handleChange = (newVal: string) => {
    onChange(filter, newVal)
  }

  return (
    <Input
      onChange={handleChange}
      value={value}
      name={filter.id}
      label={filter.label}
      required={filter.mandatory}
      labelPosition={"fixed"}
    />
  )
}
