import React from "react"
import { FilterByDate } from "../../api/types/dashboard_detail_post.response"
import { IonLabel, IonList } from "@ionic/react"
import Message from "../Message/Message"
import useTranslate from "../../hooks/useTranslate"
import Select from "../Select/Select"
import DateInput from "../DateInput/DateInput"
import InputItem from "../InputItem/InputItem"

type Period = "weekly" | "monthly" | "yearly"

export type FilterDateValue = {
  from?: number
  to?: number
  period?: Period
}

interface IFilterDateProps {
  onChange: (filter: FilterByDate, value: FilterDateValue) => void
  filter: FilterByDate
  value?: FilterDateValue
}

export default function FilterDate({
  filter,
  value,
  onChange,
}: IFilterDateProps) {
  const translate = useTranslate()
  const { to, from, period } = value || {}

  const periodValues: [Period, string][] = [
    ["weekly", translate("weekly")],
    ["monthly", translate("monthly")],
    ["yearly", translate("yearly")],
  ]

  const handlePeriodChange = (period: Period) => {
    onChange(filter, { to, from, period })
  }

  const handleFromChange = (from: number) => {
    onChange(filter, { to, from, period })
  }

  const handleToChange = (to: number) => {
    onChange(filter, { to, from, period })
  }

  return (
    <>
      <IonList>
        <InputItem>
          <IonLabel>
            <Message id={"period"} />
          </IonLabel>
          <Select<Period>
            slot={"end"}
            onChange={handlePeriodChange}
            label={translate("periodPlaceholder")}
            items={periodValues}
            value={period}
          />
        </InputItem>
        <InputItem>
          <IonLabel>
            <Message id={"from"} />
          </IonLabel>
          <DateInput
            onChange={handleFromChange}
            value={from}
            name={"from"}
            min={filter.minValue}
          />
        </InputItem>
        <InputItem>
          <IonLabel>
            <Message id={"to"} />
          </IonLabel>
          <DateInput
            onChange={handleToChange}
            value={to}
            name={"to"}
            max={filter.maxValue}
          />
        </InputItem>
      </IonList>
    </>
  )
}
