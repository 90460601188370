import { Redirect, Route } from "react-router-dom"
import {
  IonApp,
  IonRouterOutlet, IonSplitPane,
  setupIonicReact
} from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import Login from "./pages/Login/Login"
import { FC } from "react"
import Dashboards from "./pages/Dashboards/Dashboards"
import Error from "./pages/Error/Error"
import "@ionic/react/css/core.css"
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/padding.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"
import "./theme/variables.css"
import "./theme/typography.css"
import "./theme/utils.css"
import "./theme/overrides.css"
import Favourites from "./pages/Favourites/Favourites"
import Navigation from "./components/Navigation/Navigation"
import config from "./config"
import Dashboard from "./pages/Dashboard/Dashboard"
import { useStart } from "./api/hooks"

setupIonicReact()

const App: FC = () => {
  const { isLoggedIn, error } = useStart()
  return (
    <IonApp>
      {error ? (
        <Error error={error} />
      ) : isLoggedIn ? (
        <IonReactRouter>
            <IonSplitPane contentId="main" when={false}>
              <Navigation />
              <IonRouterOutlet id="main">
                <Route exact path={config.router.routes.favourites()}>
                  <Favourites />
                </Route>
                <Route exact path={config.router.routes.dashboards()}>
                  <Dashboards />
                </Route>
                <Route exact path={config.router.routes.dashboard()}>
                  <Dashboard />
                </Route>
                <Route exact path="/">
                  <Redirect to={config.router.routes.favourites()} />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
        </IonReactRouter>
      ) : (
        <Login />
      )}
    </IonApp>
  )
}

export default App
