import React from "react"
import { ListOfDashboards } from "../../api/types/dashboard_list_get.response"
import Message from "../Message/Message"
import { IonCol, IonGrid, IonRow } from "@ionic/react"
import DashboardCard from "../DashboardCard/DashboardCard"
import { useFavouritesState } from "../../context/favouritesContext"

interface IDashboardListProps {
  list: ListOfDashboards
  isLoading: boolean
}

export default function DashboardList({
  list,
  isLoading,
}: IDashboardListProps) {
  const [favourites, setFavourites] = useFavouritesState()

  const handleAddToFavourites = (id: string) => {
    const alter = favourites.includes(id)
      ? favourites.filter((i) => i !== id)
      : [...favourites, id]
    setFavourites(alter)
  }

  if (list.length === 0 && !isLoading) return <Message id={"emptyDashboards"} />

  return (
    <IonGrid>
      <IonRow>
        {list.length === 0
          ? Array.from({ length: 4 }).map((_, id) => (
              <IonCol size={"6"} sizeLg={"4"} sizeXl={"3"} key={id}>
                <DashboardCard />
              </IonCol>
            ))
          : list.map(({ id, label, icon, description, annotation }) => (
              <IonCol size={"6"} sizeLg={"4"} sizeXl={"3"} key={id}>
                <DashboardCard
                  favourite={favourites.includes(id)}
                  help={annotation}
                  onAddToFavourites={handleAddToFavourites}
                  id={id}
                  label={label}
                  icon={icon}
                  description={isLoading ? undefined : description}
                />
              </IonCol>
            ))}
      </IonRow>
    </IonGrid>
  )
}
