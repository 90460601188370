import { FilterValue } from "../components/FilterInput/FilterInput"

export class ClientError extends Error {
  constructor({ status }: Response) {
    super()
    this.message = status.toString()
    this.name = this.constructor.name
  }
}

export class ServerError extends Error {
  constructor({ status }: Response) {
    super()
    this.message = status.toString()
    this.name = this.constructor.name
  }
}

export function transformFilterValueToArray(value: FilterValue): string[] {
  if (Array.isArray(value)) return value
  if (typeof value === "string") return [value]
  if ("from" in value) {
    return [value.from?.toString() || "", value.to?.toString() || ""]
  }
  return []
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE"
export type Params = Record<string, string>
export type Body = Record<any, any>
export type ApiErrorType = "network" | "server" | "unknown"

export interface FetchError {
  type: ApiErrorType
}

export async function fetcher<T>(
  url: string,
  method: HttpMethod,
  token: string | null,
  params?: Params,
  body?: Body
): Promise<T> {
  return await fetch(`${url}${createQuery(params)}`, {
    method: method,
    headers: token ? { "X-ASW-TOKEN": token } : {},
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (res.status >= 400 && res.status < 500) throw new ClientError(res)
      if (res.status >= 500) throw new ServerError(res)
      return res
    })
    .then((res) => res.json())
}

export function createQuery(params?: Record<string, string>): string {
  if (!params) return ``
  return Object.entries(params).reduce((acc, [key, value]) => {
    const separator = acc === `?` ? `` : `&`
    if (value === undefined || value === null) return acc
    return `${acc}${separator}${key}=${value}`
  }, `?`)
}
