import React, { useMemo } from "react"
import { Filter, Graphs } from "../../api/types/dashboard_detail_post.response"
import { IonCol, IonGrid, IonRow } from "@ionic/react"
import GraphCard from "../GraphCard/GraphCard"
import { useGraphDetails } from "../../api/hooks"
import { Graph } from "../../api/types/customApiTypes"
import "./GraphList.css"

interface IGraphListProps {
  graphs: Graphs
  dashboardFilters?: Filter[]
}

type GraphMap = Map<string, Graph>

export default function GraphList({
  graphs,
  dashboardFilters,
}: IGraphListProps) {
  const { data } = useGraphDetails(graphs.map(({ id }) => id))
  const graphMap = useMemo<GraphMap>(
    () => new Map(data?.graphs?.map((graph) => [graph.id, graph])),
    [data?.graphs]
  )

  return (
    <IonGrid className={"graph-list"}>
      <IonRow>
        {graphs
          .sort((a, b) => (a.ordNumber || 0) - (b.ordNumber || 0))
          .map((graph) => (
            <IonCol
              size={"12"}
              key={graph.id}
              sizeXs={graph.size?.xs?.toString()}
              sizeSm={graph.size?.sm?.toString()}
              sizeMd={graph.size?.md?.toString()}
              sizeLg={graph.size?.lg?.toString()}
              sizeXl={graph.size?.xl?.toString()}
            >
              <GraphCard
                key={graph.id}
                id={graph.id}
                label={graphMap.get(graph.id)?.label}
                annotation={graphMap.get(graph.id)?.annotation}
                description={graphMap.get(graph.id)?.description}
                aspectRatio={graph.aspectRatio}
                filters={graphMap
                  .get(graph.id)
                  ?.filters?.map((filter): Filter | undefined =>
                    "dashboardId" in filter
                      ? dashboardFilters?.find(
                          (dashFilter) => dashFilter.id === filter.dashboardId
                        )
                      : filter
                  )
                  .filter((i): i is Filter => !!i)}
              />
            </IonCol>
          ))}
      </IonRow>
    </IonGrid>
  )
}
